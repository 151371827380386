import { put, takeLatest } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB } from 'constants/'
import { fetchSimilarCoursesAction } from 'components/Learning/store'
import { push, replace } from 'connected-react-router'
import { CertificateNeutralPath, pageNotFoundPath } from 'navigation/Routes'
import { TurnOffLoaderAction, TurnOnLoaderAction } from 'store/config'
import { decorateUrl } from 'components/common/UTMscript'

const initialState = {
  error: false,
  isLoading: true
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CERTIFICATE_FETCH_REQUESTED: {
      return {
        ...state,
        isLoading: true
      }
    }
    case GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        ...data,
        isLoading: false
      }
    case GET_CERTIFICATE_FAILED:
      return {
        ...state,
        error: data,
        isLoading: false
      }
    case CLEAR_CERTIFICATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const fetchCertificateByIdAction = (certificateId) => ({ type: CERTIFICATE_FETCH_REQUESTED, data: certificateId })
export const clearCertificateAction = () => ({ type: CLEAR_CERTIFICATE })
export const generateCertificateAction = (certificate) => ({ type: GENERATE_CERTIFICATE, data: certificate })

function * fetchCertificateById ({ data }) {
  yield put(TurnOnLoaderAction())
  try {
    const response = yield fetch(queries.getCertificateById(data))
    if (response.Id) yield put(fetchSimilarCoursesAction([response.CourseId], 8))
    else throw new Error('Not Found')
    yield put({ type: GET_CERTIFICATE_SUCCESS, data: response })
    yield put(replace(decorateUrl(`${CertificateNeutralPath}/${response.Id}`)))
    yield put(TurnOffLoaderAction())
  } catch (err) {
    yield put(push(decorateUrl(pageNotFoundPath)))
    yield put(TurnOffLoaderAction())
    yield put({ type: GET_CERTIFICATE_FAILED, err })
  }
}

function * generateCertificate ({ data }) {
  try {
    const response = yield fetch(`${BASE_URL_WEB}/User/DownloadCertificate/${data.Id}`)
    if (!response.ok) throw new Error('Failed to download certificate')
    yield put({ type: GENERATE_CERTIFICATE_SUCCESS })
  } catch (error) {
    yield put({ type: GENERATE_CERTIFICATE_FAILED, error })
  }
}

export function * certificateRootSaga () {
  yield takeLatest(CERTIFICATE_FETCH_REQUESTED, fetchCertificateById)
  yield takeLatest(GENERATE_CERTIFICATE, generateCertificate)
}

const queries = {
  getCertificateById: data => `${BASE_URL_WEB}/User/GetCertificate/${data}`
}

export const GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS'
export const GET_CERTIFICATE_FAILED = 'GET_CERTIFICATE_FAILED'
const CERTIFICATE_FETCH_REQUESTED = 'CERTIFICATE_FETCH_REQUESTED'
const CLEAR_CERTIFICATE = 'CLEAR_CERTIFICATE'
export const GENERATE_CERTIFICATE = 'GENERATE_CERTIFICATE'
export const GENERATE_CERTIFICATE_SUCCESS = 'GENERATE_CERTIFICATE_SUCCESS'
export const GENERATE_CERTIFICATE_FAILED = 'GENERATE_CERTIFICATE_FAILED'
